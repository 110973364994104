$(() => {
    $.getScript('https://keaz.ru/landing/captcha.js', () => {
        const hintData = {
            finished__goods__warehouse: {
                title: "Склад готовой продукции",
                text: "Мы оборудовали собственные склады, чтобы оперативно поставлять продукцию нашим клиентам и выполнять стратегически важные заказы. Сейчас склады КЭАЗ находятся в Москве, Новосибирске, Екатеринбурге, Самаре.",
                img: require("./assets/img/warehouse.svg"),
                btns: [
                    {
                        href: "https://keaz.ru/contacts/sklad",
                        text: "Узнать адреса",
                    },
                ],
            },
            blacksmith__shop: {
                title: "Кузница мастеров",
                text: "Настоящие мастера трудятся в нашем Ремонтно-инструментальном производстве. Именно здесь наши “повелители” огня и металла производят оснастку, разрабатывают штампы и пресс-формы, ремонтируют и дорабатывают станки.",
                img: require("./assets/img/forge-of-masters.svg"),
                btns: [
                    {
                        href: "https://www.youtube.com/watch?v=rEqYaMGQLK0&t=26s",
                        text: "Познакомиться с мастерами",
                    },
                ],
            },
            recycling: {
                title: "Учебный центр",
                text: "Учебный центр КЭАЗ – это возможность повысить квалификацию для специалистов разного уровня. Вы можете пройти бесплатные электронные курсы, послушать вебинары о продукции, побывать на семинарах в вашем городе или познакомиться с единственным в своем роде Мобильном выставочном комплексом.",
                img: require("./assets/img/training-center.svg"),
                btns: [
                    {
                        href: "https://keaz.ru/training?ysclid=lbguz1a8eu684066207",
                        text: "Пройти обучение",
                    },
                ],
            },
            adepts: {
                title: "Адепты Бережливого производства",
                text: "Сборочное производство - настоящий адепт бережливого производства. Здесь организовано 4 заготовительных участка и 24 сборочных ячейки, выстроен поток единичных изделий, который работает по “времени такта”. А за год мы производим более 5 миллионов изделий!",
                img: require("./assets/img/adherents.svg"),
                btns: [
                    {
                        href: "https://www.youtube.com/watch?v=p2ASFZt5uQY&t=94s",
                        text: "Увидеть в действии",
                    },
                ],
            },
            detinets: {
                title: "Курский детинец",
                text: "С основания КЭАЗ находился в историческом центре Курска - Курском детинце. В 2020 году мы перенесли производство в Индустриальный парк “СОЮЗ”. Но с исторической площадкой нас связывают сотни изобретений, рекордов и ярких событий.",
                img: require("./assets/img/kursk-detinets.svg"),
                btns: [
                    {
                        href: "https://keaz.ru/company/about/history",
                        text: "Окунуться в историю КЭАЗ",
                    },
                    {
                        href: "https://www.youtube.com/watch?v=jp12eJ4D1qE",
                        text: "Открытие Детинца",
                    },
                ],
            },
            mechanical: {
                title: "Механическое производство",
                text: "Именно здесь мы производим детали вращения - оси, полуоси, втулки, распорки, валы и пружины. Диаметр изготавливаемых деталей — от 2 до 40 мм, а максимальная длина - до 210 мм. Это практически ювелирная работа.",
                img: require("./assets/img/mechanic-production.svg"),
                btns: [
                    {
                        href: "#",
                        text: "Посмотреть производство",
                    },
                    {
                        href: "https://dzen.ru/video/watch/625d527c004eff5a750b7a95",
                        text: "Создание пружинки",
                    },
                ],
            },
            region: {
                title: "Штамповочное производство",
                text: "Это практически «сердце» заготовительного производства КЭАЗ. Оно включает в себя сразу семь разных участков, на которых выполняют различные операции как с материалами, так и с деталями, предназначенными для получения готовых деталей. О том, как работает наше штамповочное производство, смотрите в новом видео.",
                img: require("./assets/img/plot-of-the-future.svg"),
                btns: [
                    {
                        href: "https://www.youtube.com/watch?v=p6N8THt6EBQ ",
                        text: "Смотреть видео",
                    },
                ],
            },
            storeroom: {
                title: "Гальваника",
                text: "Гальваническое производство с мощностью более 400 тыс. м\xb2 покрытий в год позволяет КЭАЗ быстро реагировать на потребности клиентов и оперативно поставлять комплектующие.",
                img: require("./assets/img/electroplating.svg"),
                btns: [
                    {
                        href: "https://www.youtube.com/watch?v=qGwMuN70tlE&t=19s",
                        text: "Узнать о производстве",
                    },
                ],
            },
            electroplating: {
                title: "Производство пластика",
                text: "Для нас “зеленое производство” и эко-ответственность - это не просто тренды. Мы разработали уникальную технологию переработки пластиковых отходов и производим из них качественный и прочный бмс-пластик КЭАЗит.",
                img: require("./assets/img/plastic-production.svg"),
                btns: [
                    {
                        href: "https://www.youtube.com/watch?v=f5OnJTAEU9s&t=14s",
                        text: "Узнать о КЭАЗите",
                    },
                ],
            },
            trials: {
                title: "Испытательный центр",
                text: "Испытания электротехнической продукции – один из важнейших этапов производства качественного оборудования. Только проверив оборудование в испытательном центре, мы можем гарантировать его надежность и стабильную работу.",
                img: require("./assets/img/test-center.svg"),
                btns: [
                    {
                        href: "https://www.youtube.com/watch?v=YnCiDQmsY-s&t=5s",
                        text: "Металлографическая лаборатория",
                    },
                    {
                        href: "https://youtu.be/CQ9KpOOeAkw",
                        text: "Русский испытательный центр",
                    },
                ],
            },
            car: {
                title: "Мобильный выставочный комплекс",
                text: " это настоящий учебный центр на колесах. Внутри автомобиля собраны решения  на базе НВА КЭАЗ для проведения практических занятий и мастер-классов.",
                img: require("./assets/img/car.svg"),
                btns: [
                    {
                        href: "https://mvk.keaz.ru/ ",
                        text: "Расписание семинаров",
                    },
                    {
                        href: "https://www.youtube.com/watch?v=JpHevF8AvuY&list=PLvpkBVpp0aDHZ4aCtgZhNELxdWgRbp67O",
                        text: "Отзывы",
                    },
                ],
            },
        };

        const element = document.getElementById("map");
        const panzoom = Panzoom(element, {
            cursor: "default",
        });
        $(".city-map__btn_in").on("click", function () {
            panzoom.zoomIn("panzoom");
        });
        $(".city-map__btn_out").on("click", function () {
            panzoom.zoomOut("panzoom");
        });
        $(".reset").on("click", function () {
            panzoom.reset("panzoom");
        });
        $(".map__link").click(function () {
            $(".desription__button__tovisit").html("");
        });

        $(".map__link").on("click", function (e) {
            $(".desription").toggle();
            e = e || window.event;
            e.preventDefault();
            const ypos = $(this).offset().top - 150;
            const xpos = $(this).offset().left + 10;
            const hintId = $(this).data("hint");
            const currentData = hintData[hintId];
            if (hintId && currentData) {
                const {title, text, img, btns} = currentData;
                $("#desription .desription__clarity .desription__clarity__heading").text(
                    title
                );
                $("#desription .desription__clarity .desription__clarity__text").text(
                    text
                );
                $("#desription .desription__content__building .img").attr("src", img);
                if (btns) {
                    var actionBtns = $();
                    btns.forEach((item) => {
                        actionBtns = actionBtns.add(
                            `<a class="desription__to__visit" target="_blank" href="${item.href}">${item.text}</a>`
                        );
                    });
                    $(".desription__button__tovisit").append(actionBtns);
                }
            }

            setTimeout(() => {
                $(".desription")
                    .css({
                        top: ypos,
                        left: xpos,
                    })
                    .show("fast");
            }, 300);
            return false;
        });
        $(".desription__cross").on("click", function () {
            $(".desription").hide("fast");
        });
        $(".map").on("click", (e) => {
            if (
                !$(e.target).hasClass(".desription") ||
                !$(e.target).hasClass(".map__link")
            )
                $(".desription").hide("fast");
        });
        const handleCloseMenu = () => {
            $("body").removeClass("header__page_lock");
            $(".header__burger__menu").removeClass("header__burger__menu__active");
            $(".header__burger__line").removeClass("burger__line__active");
        };
        const handleOpenMenu = () => {
            $("body").addClass("header__page_lock");
            $(".header__burger__menu").addClass("header__burger__menu__active");
            $(".header__burger__line").addClass("burger__line__active");
        };
        // $(".header__burger__line").on("click", function () {
        //     handleCloseMenu();
        // });
        $(".header__burger__button").on("click", function () {
            if ($(".header__burger__line").hasClass("burger__line__active"))
                handleCloseMenu();
            else handleOpenMenu();
        });

        $("#menu__button__burger").click(function () {
            $("html, body").animate(
                {
                    scrollTop: $(".excursion").offset().top,
                },
                1000
            );
        });

        $(".header__button__text__to_visit").click(function () {
            $("html, body").animate(
                {
                    scrollTop: $(".excursion").offset().top,
                },
                1000
            );
        });

        $(".footer__up").click(function () {
            $("html, body").animate(
                {
                    scrollTop: $(".header").offset().top,
                },
                1000
            );
        });

        $("document").ready(function () {
            setTimeout(function () {
                $(".city-map__btn_in").trigger("click");
            }, 10);
        });

        new AirDatepicker("#datepicker", {
            autoClose: true,
        });

        $("#excursion__fillings").on('submit', (e) => {
            e.preventDefault();
            const messageArea = $('.excursion__message');
            messageArea.innerHTML = '';

            const dateInput = $('#datepicker')[0];

            if (dateInput.value === ''){
                messageArea
                    .removeClass('excursion__success__message')
                    .addClass('excursion__error__message')
                    .text('Не заполнено поле даты визита!');
                return;
            }

            window.keazCaptchaService('https://keaz.ru/helpdesk/gorod/registration', e.target)
                .then((_result) => {
                    messageArea
                        .removeClass('excursion__error__message')
                        .addClass('excursion__success__message')
                        .text(_result.messages);
                })
                .catch((_errorResult) => {
                    messageArea
                        .removeClass('excursion__success__message')
                        .addClass('excursion__error__message')
                        .text(_errorResult);
                });
        });
    })
});
